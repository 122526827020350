import firebase from "firebase"
const config = {
    apiKey: "AIzaSyCnlKxq6arF2BefUDWh5HS0kzMYUJS_C2s",
    authDomain: "tournament-b2ee2.firebaseapp.com",
    databaseURL: "https://tournament-b2ee2.firebaseio.com",
    projectId: "tournament-b2ee2",
    storageBucket: "tournament-b2ee2.appspot.com",
    messagingSenderId: "278876724298",
    appId: "1:278876724298:web:c52d1f9cf228e050159ab3",
    measurementId: "G-H1BZ8MH31N"
};

const app = firebase.initializeApp(config);
const storage = app.storage();
const db = app.database();

export const firebaseConfig = {
    firebase,
    app,
    storage,
    db
};
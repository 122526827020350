import { commonConstants } from "../constants";

const initialState = {loading: false, settings: null};

export const settingsReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case commonConstants.COMMON_SETTINGS_START:
            return {
                ...state,
                loading: true
            };
        case commonConstants.COMMON_SETTINGS_STOP:
            return {
                ...state,
                loading: false
            };
        case commonConstants.COMMON_SETTINGS:
            return {
                ...state,
                settings: payload.data
            };
        case commonConstants.COMMON_SETTINGS_UPDATE:
            const index = state.settings.findIndex(f => f.key === payload.update.key);
            console.log('sdfsd', index, state, payload )
            return {
                ...state,
                settings: [...state.settings.slice(0,index),
                    payload.update,
                    ...state.settings.slice(index+1)
                ],
            };
        default:
            return state
    }
};
import {commonConstants} from '../constants';

const categories = (res) => {
    return {type: commonConstants.COMMON_CATEGORIES, payload: {data: res}};
};

const userCategories = (res) => {
    return {type: commonConstants.COMMON_USER_CATEGORIES, payload: {data: res}};
};

const skills = (res) => {
    return {type: commonConstants.COMMON_SKILLS, payload: {data: res}};
};

const countries = (res) => {
    return {type: commonConstants.COMMON_COUNTRIES, payload: {data: res}};
};

const questions = (res) => {
    return {type: commonConstants.COMMON_QUESTIONS, payload: {data: res}};
};

const platforms = (res) => {
    return { type: commonConstants.COMMON_PLATFORMS, payload: { data: res } };
};

const config = (res) => {
    return { type: commonConstants.COMMON_CONFIG, payload: { data: res } };
};

const content = (res) => {
    return { type: commonConstants.COMMON_CONTENT, payload: { data: res } };
};

const slider = (res) => {
    return { type: commonConstants.COMMON_SLIDER, payload: { data: res } };
};

const game_slider = (res) => {
    return { type: commonConstants.COMMON_GAME_SLIDER, payload: { data: res } };
};

const pages = (res) => {
    return { type: commonConstants.COMMON_PAGES, payload: { data: res } };
};

const pageStart = () => {
    return {type: commonConstants.COMMON_PAGES_START, payload: {loading: true}};
};

const pageStop = () => {
    return {type: commonConstants.COMMON_PAGES_STOP, payload: {loading: false}};
};

const settings = (res) => {
    return { type: commonConstants.COMMON_SETTINGS, payload: { data: res } };
};

const settingUpdate = (res) => {
    return { type: commonConstants.COMMON_SETTINGS_UPDATE, payload: { update: res } };
};

const settingStart = () => {
    return {type: commonConstants.COMMON_SETTINGS_START, payload: {loading: true}};
};

const settingStop = () => {
    return {type: commonConstants.COMMON_SETTINGS_STOP, payload: {loading: false}};
};

export const commonSelectors = {
    categories,
    userCategories,
    skills,
    countries,
    questions,
    platforms,
    config,
    content,
    slider,
    game_slider,
    pages,
    pageStart,
    pageStop,
    settings,
    settingUpdate,
    settingStart,
    settingStop,
};

import { commonConstants } from "../constants";

const initialState = {loading: false, data: [], view: '', slider: null, game_slider: null};

export const pagesReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case commonConstants.COMMON_PAGES_START:
            return {
                ...state,
                loading: true
            };
        case commonConstants.COMMON_PAGES_STOP:
            return {
                ...state,
                loading: false
            };
        case commonConstants.COMMON_PAGES:
            return {
                ...state,
                data: payload.data
            };
        case commonConstants.COMMON_SLIDER:
            return {
                ...state,
                slider: payload.data
            };
        case commonConstants.COMMON_GAME_SLIDER:
            //console.log('payload.data', payload.data);
            return {
                ...state,
                game_slider: payload.data
            };
        default:
            return state
    }
};
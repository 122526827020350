import { teamsConstants } from "../constants";

const start = () => {
    return {type: teamsConstants.TEAMS_PROCESS_START, payload: {loading: true}};
};

const stop = () => {
    return {type: teamsConstants.TEAMS_PROCESS_STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: teamsConstants.TEAMS_LIST, payload: {list: res}};
};

const view = (res) => {
    return {type: teamsConstants.TEAMS_VIEW, payload: {view: res}};
};

const add = (res) => {
    return {type: teamsConstants.TEAMS_ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: teamsConstants.TEAMS_UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: teamsConstants.TEAMS_REMOVE, payload: {remove: res}};
};

const clear = (res) => {
    return {type: teamsConstants.TEAMS_CLEAR, payload: {clear: res}};
};

const teamPlayerList = (res) => {
    return {type: teamsConstants.TEAMS_PLAYER_LIST, payload: {players: res}};
};

const teamPlayerAdd = (res) => {
    return {type: teamsConstants.TEAMS_PLAYER_ADD, payload: {player: res}};
};

const teamPlayerRemove = (res) => {
    return {type: teamsConstants.TEAMS_PLAYER_REMOVE, payload: {remove: res}};
};

export const teamsSelectors = {
    start,
    stop,
    list,
    view,
    add,
    update,
    remove,
    clear,
    teamPlayerList,
    teamPlayerAdd,
    teamPlayerRemove
}
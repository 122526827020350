export const teamsConstants = {
    TEAMS_PROCESS_START: 'teams:start',
    TEAMS_PROCESS_STOP: 'teams:stop',
    TEAMS_LIST: 'teams:list',
    TEAMS_VIEW: 'teams:view',
    TEAMS_ADD: 'teams:add',
    TEAMS_UPDATE: 'teams:update',
    TEAMS_REMOVE: 'teams:delete',
    TEAMS_CLEAR: 'teams:clear',
    TEAMS_PLAYER_LIST: 'teams:player:list',
    TEAMS_PLAYER_ADD: 'teams:player:add',
    TEAMS_PLAYER_REMOVE: 'teams:player:remove',
}
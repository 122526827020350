import { countriesConstants } from "../constants";

const start = () => {
    return {type: countriesConstants.COUNTRIES_START, payload: {loading: true}};
};

const stop = () => {
    return {type: countriesConstants.COUNTRIES_STOP, payload: {loading: false}};
};

const countries = (res) => {
    return {type: countriesConstants.COUNTRIES_COUNTRIES, payload: {list: res}};
};

const clear = (res = null) => {
    return {type: countriesConstants.COUNTRIES_CLEAR, payload: {clear: res}};
};

export const countriesSelectors = {
    start,
    stop,
    countries,
    clear,
}

import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { userReducer  } from './user.reducer';
import { alertReducer } from './alert.reducer';
import { processReducer } from './process.reducer';
import { uploadReducer } from './upload.reducer';
import { countriesReducer } from './countries.reducer';
import { configReducer } from './config.reducer';
import { statesReducer } from './states.reducer';
import { notificationReducer } from './notification.reducer';
import { categoriesReducer } from './categories.reducer';
import { teamsReducer } from './teams.reducer';
import { pagesReducer } from './pages.reducer';
import { settingsReducer } from './settings.reducer';
import { invitationReducer } from './invitation.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { messageReducer } from './message.reducer';

const rootReducer = combineReducers({
    authentication: authReducer,
    process: processReducer,
    alert: alertReducer,
    upload: uploadReducer,
    user: userReducer,
    countries: countriesReducer,
    states: statesReducer,
    config: configReducer,
    notifications: notificationReducer,
    categories: categoriesReducer,
    teams: teamsReducer,
    pages: pagesReducer,
    settings: settingsReducer,
    invitation: invitationReducer,
    dashboard: dashboardReducer,
    message: messageReducer,
});

export default rootReducer;

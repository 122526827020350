import { dashboardConstants } from "../constants";

const start = () => {
    return {type: dashboardConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: dashboardConstants.STOP, payload: {loading: false}};
};

const myTeams = (res) => {
    return {type: dashboardConstants.MYTEAMS, payload: {myTeams: res}};
};

const joinedTeams = (res) => {
    return {type: dashboardConstants.JOINEDTEAMS, payload: {joinedTeams: res}};
};

const teamInvitations = (res) => {
    return {type: dashboardConstants.TEAMINVITATIONS, payload: {teamInvitations: res}};
};

const tournamentInvitations = (res) => {
    return {type: dashboardConstants.TOURNAMENTINVITATIONS, payload: {tournamentInvitations: res}};
};

const currentTournaments = (res) => {
    return {type: dashboardConstants.CURRENTTOURNAMENTS, payload: {currentTournaments: res}};
};

const pastTournaments = (res) => {
    return {type: dashboardConstants.PASTTOURNAMENTS, payload: {pastTournaments: res}};
};

const clear = (res) => {
    return {type: dashboardConstants.CLEAR, payload: {clear: res}};
};

export const dashboardSelectors = {
    start,
    stop,
    myTeams,
    joinedTeams,
    teamInvitations,
    tournamentInvitations,
    currentTournaments,
    pastTournaments,
    clear,
}
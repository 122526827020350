import { globalService as gs } from "./global.service";

const execute = (requestKey, method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/${requestKey}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

/* ** cms-item ** */
const item = (method = "GET", params = null, params2 = null) => {
    /* return execute('item/index', method, params, params2); */
    let url = gs.httpURL(`${gs.apiRoot}/item/${gs.identity ? 'index' : 'public-index'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const userCategories = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/categories`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const reactions = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/reactions`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const comment = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/comments`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const reply = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/reply`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const stripeConnect = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/stripe-connect`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const stripeDisconnect = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/stripe-disconnect`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const stripeRetrieve = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/stripe-retrieve`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, null));
};

const logout =  (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/profile`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

/** User */

const deactivateAccount = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/deactivate-account`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const profile = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'profile' : 'index'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const changeEmail = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/change-email`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const changePassword = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/change-password`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const newsletter = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/newsletter`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const transactions = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/transactions`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, null));
};


/** Tournaments */
const teams = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'teams' : 'public-teams'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const request = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/request`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const searchTeamPlayers = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/search-team-players`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const players = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'players' : 'public-players'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const invitePlayer = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/invite-player`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const processInvitations = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/process-invitations`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const teamInvitations = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/team-invitations`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const invitedPlayers = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/invited-players`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const games = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'games' : 'public-games'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const tournaments = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'tournaments' : 'public-tournaments'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const apply = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/apply`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const score = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/score`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const searchTournamentPlayers = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/search-tournament-players`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const tournamentInvitations = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/tournament-invitations`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const tournamentDeclined = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/declined`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const rules = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/rules`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const joinTeams = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/join-teams`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const match = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/match`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const giftCertificate = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/gift-certificate`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const message = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/message`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

export const itemService = {
    execute,
    item,
    userCategories,
    reactions,
    comment,
    reply,

    /* user */
    changeEmail,
    changePassword,
    deactivateAccount,
    stripeConnect,
    stripeDisconnect,
    stripeRetrieve,
    newsletter,
    profile,
    logout,
    transactions,

    /** Tournaments **/
    teams,
    request,
    searchTeamPlayers,
    players,
    invitePlayer,
    processInvitations,
    teamInvitations,
    invitedPlayers,
    games,
    tournaments,
    apply,
    score,
    searchTournamentPlayers,
    tournamentInvitations,
    tournamentDeclined,
    rules,
    joinTeams,
    match,
    giftCertificate,
    message
};

export const dashboardConstants = {    
    START: 'dashboard:start',
    STOP: 'dashboard:stop',
    MYTEAMS: 'dashboard:myteams',
    JOINEDTEAMS: 'dashboard:joinedteams',
    TEAMINVITATIONS: 'dashboard:teaminvitations',
    TOURNAMENTINVITATIONS: 'dashboard:tournamentinvitations',
    CURRENTTOURNAMENTS: 'dashboard:currenttournaments',
    PASTTOURNAMENTS: 'dashboard:pasttournaments',
    CLEAR: 'dashboard:clear',
}
import { invitationConstants } from "../constants/invitation.constants";


const initialState = {teamLoading: false, tournamentLoading: false, teams: [], tourtament: []};

export const invitationReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case invitationConstants.INVITATION_TEAM_PROCESS_START:
            return {
                ...state,
                teamLoading: true
            };
        case invitationConstants.INVITATION_TEAM_PROCESS_STOP:
            return {
                ...state,
                teamLoading: false
            };
        case invitationConstants.INVITATION_TOURNAMENT_PROCESS_START:
            return {
                ...state,
                tournamentLoading: true
            };
        case invitationConstants.INVITATION_TOURNAMENT_PROCESS_STOP:
            return {
                ...state,
                tournamentLoading: false
            };
        case invitationConstants.INVITATION_TEAM_LIST:
            return {
                ...state,
                teams: payload.teams
            };
        case invitationConstants.INVITATION_TEAM_ACCEPT:
            const newTeam = state.teams.items;
            const newTeam2 = newTeam.filter( val => parseInt(val.team_id) !== parseInt(payload.team.team_id) );
            const updateTeams = {
                pagination: state.teams.pagination,
                items: newTeam2
            };
            return {
                ...state,
                teams: updateTeams
            };
        case invitationConstants.INVITATION_TOURTAMENT_LIST:
            return {
                ...state,
                tourtament: payload.tournament
            };
        case invitationConstants.INVITATION_TOURTAMENT_DECLINE:
            const newTournament = state.tourtament.items;
            const newTournament2 = newTournament.filter( val => parseInt(val.tournament_id) !== parseInt(payload.tournament) );
            const updateTournament = {
                pagination: state.tourtament.pagination,
                items: newTournament2
            };
            return {
                ...state,
                tourtament: updateTournament
            };

        default:
            return state
    }
}
import { dashboardConstants } from "../constants";

const initialState = {loading: false, myTeams: null, joinedTeams: null, teamInvitations: null, tournamentInvitations: null, currentTournaments: null, pastTournaments: null};

export const dashboardReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case dashboardConstants.START:
            return {
                ...state,
                loading: true
            };
        case dashboardConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case dashboardConstants.MYTEAMS:
            return {
                ...state,
                myTeams: payload.myTeams
            };
        case dashboardConstants.JOINEDTEAMS:
            return {
                ...state,
                joinedTeams: payload.joinedTeams
            };
        case dashboardConstants.TEAMINVITATIONS:
            return {
                ...state,
                teamInvitations: payload.teamInvitations
            };
        case dashboardConstants.TOURNAMENTINVITATIONS:
            return {
                ...state,
                tournamentInvitations: payload.tournamentInvitations
            };
        case dashboardConstants.CURRENTTOURNAMENTS:
            return {
                ...state,
                currentTournaments: payload.currentTournaments
            };
        case dashboardConstants.PASTTOURNAMENTS:
            return {
                ...state,
                pastTournaments: payload.pastTournaments
            };

        default:
            return state
    }
}
export const invitationConstants = {
    INVITATION_TEAM_PROCESS_START: 'invitation:team:start',
    INVITATION_TEAM_PROCESS_STOP: 'invitation:team:stop',
    INVITATION_TOURNAMENT_PROCESS_START: 'invitation:tournament:start',
    INVITATION_TOURNAMENT_PROCESS_STOP: 'invitation:tournament:stop',
    INVITATION_TEAM_LIST: 'invitation:team:list',
    INVITATION_TEAM_ACCEPT: 'invitation:team:accept',
    INVITATION_TEAM_DECLINE: 'invitation:team:decline',
    INVITATION_TOURTAMENT_LIST: 'invitation:tournament:list',
    INVITATION_TOURTAMENT_ACCEPT: 'invitation:tournament:accept',
    INVITATION_TOURTAMENT_DECLINE: 'invitation:tournament:decline',
    INVITATION_CLEAR: 'invitation:clear',
}
import { invitationConstants } from "../constants/invitation.constants";


const teamStart = () => {
    return {type: invitationConstants.INVITATION_TEAM_PROCESS_START, payload: {loading: true}};
};

const teamStop = () => {
    return {type: invitationConstants.INVITATION_TEAM_PROCESS_STOP, payload: {loading: false}};
};

const tournamentStart = () => {
    return {type: invitationConstants.INVITATION_TOURNAMENT_PROCESS_START, payload: {loading: true}};
};

const tournamentStop = () => {
    return {type: invitationConstants.INVITATION_TOURNAMENT_PROCESS_STOP, payload: {loading: false}};
};

const teamList = (res) => {
    return {type: invitationConstants.INVITATION_TEAM_LIST, payload: {teams: res}};
};

const teamAccept = (res) => {
    return {type: invitationConstants.INVITATION_TEAM_ACCEPT, payload: {team: res}};
};

const tournamentList = (res) => {
    return {type: invitationConstants.INVITATION_TOURTAMENT_LIST, payload: {tournament: res}};
}

const tournamentDecline = (res) => {
    return {type: invitationConstants.INVITATION_TOURTAMENT_DECLINE, payload: {tournament: res}};
}

const clear = (res) => {
    return {type: invitationConstants.INVITATION_CLEAR, payload: {clear: res}};
};

export const invitationSelectors = {
    teamStart,
    teamStop,
    tournamentStart,
    tournamentStop,
    teamList,
    teamAccept,
    tournamentList,
    tournamentDecline,
    clear,
}
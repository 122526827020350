import { commonConstants } from '../constants/common.constants';

const initialState = {config: null};

export const configReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case commonConstants.COMMON_CONFIG:
            return {
                type: commonConstants.COMMON_CONFIG,
                config: payload.data
            };
        default:
            return state
    }
};
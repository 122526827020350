import { countriesConstants } from "../constants";

const initialState = {loading: false, countries: []};

export const countriesReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case countriesConstants.COUNTRIES_START:
            return {
                ...state,
                loading: true
            };
        case countriesConstants.COUNTRIES_STOP:
            return {
                ...state,
                loading: false
            };
        case countriesConstants.COUNTRIES_COUNTRIES:
            return {
                ...state,
                countries: payload.list
            };
        case countriesConstants.COUNTRIES_CLEAR:
            return {
                ...state,
                countries: []
            };
        default:
            return state
    }
};
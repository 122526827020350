import { teamsConstants } from "../constants";

const initialState = {loading: false, data: [], view: '', players: []};

export const teamsReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case teamsConstants.TEAMS_PROCESS_START:
            return {
                ...state,
                loading: true
            };
        case teamsConstants.TEAMS_PROCESS_STOP:
            return {
                ...state,
                loading: false
            };
        case teamsConstants.TEAMS_LIST:
            return {
                ...state,
                data: payload.list
            };
        case teamsConstants.TEAMS_VIEW:
            return {
                ...state,
                view: payload.view
            };
        case teamsConstants.TEAMS_ADD:
            return {
                ...state,
                data: [...state.data.concat(payload.add)],
            };
        case teamsConstants.TEAMS_UPDATE:
            const index = state.data.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            //console.log('sdfds', payload.update, index);
            return {
                ...state,
                data: [...state.data.slice(0,index),
                    payload.update,
                    ...state.data.slice(index+1)
                ],
            };
        case teamsConstants.TEAMS_REMOVE:
            return {
                ...state,
                data: [...state.data.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case teamsConstants.TEAMS_PLAYER_LIST:
            return {
                ...state,
                players: payload.players
            };
        case teamsConstants.TEAMS_PLAYER_ADD:
            const newPlayer = state.players.items;
            newPlayer.push(payload.player);
            const updatePlayers = {
                pagination: state.players.pagination,
                items: newPlayer
            };
            return {
                ...state,
                players: updatePlayers,
            };
        case teamsConstants.TEAMS_PLAYER_REMOVE:
            const newPlayerRemove = state.players.items;
            const updatePlayerRemove = newPlayerRemove.filter( val => parseInt(val.player_id) !== parseInt(payload.remove.player_id) );
            const updatePlayersRemove = {
                pagination: state.players.pagination,
                items: updatePlayerRemove
            };
            return {
                ...state,
                players: updatePlayersRemove,
            };
        default:
            return state
    }
};